<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" @open="openDialog" width="50%" top="5vh">
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="120px"
      class="demo-form-inline"
    >
      <el-form-item label="商户名称" prop="merchantId" style="width: 50%">
        <el-select
          clearable
          v-model="formInfo.merchantId"
          placeholder="请选择要购买的商户"
          class="w100"
        >
          <el-option
            v-for="item in merchantList"
            :key="item.id"
            :label="item.merchantName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程账号类型：" prop="courseGoodsId" style="width: 50%">
        <el-select
          clearable
          v-model="formInfo.courseGoodsId"
          placeholder="请选择课程账号类型"
          class="w100"
        >
          <el-option
            v-for="item in courseCatalogList"
            :key="item.id"
            :label="item.courseGoodsName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课时数：" prop="buyTotalAmount" style="width: 50%">
        <el-input
          clearable
          v-model="formInfo.buyTotalAmount"
          placeholder="请输入课时数"
          class="w100"
        ></el-input>
      </el-form-item>
      <div style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')"
        >确定
        </el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import {
  getMerchantList,
  buyCourseGoods,
  // distributeCourseOnBuyGoods,
} from "@/api/member/merchant";
import {
  courseCatalog,
} from '@/api/research/curriculum';

export default {
  props: {
    title: {type: String, default: ""},
  },
  data() {
    return {
      checkedNodeList:[],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      dialogVisible: false,
      courseCatalogList: [],
      merchantList: [],
      formInfo: {
        merchantId: "",
      },
      rules: {
        merchantId: [
          {required: true, message: "请输入校区名称", trigger: "blur"},
        ],
        buyTotalAmount: [
          {required: true, message: "请输入课程账号数", trigger: "blur"},
        ],
        courseGoodsId: [
          {required: true, message: "请选择课程账号类型", trigger: "blur"},
        ],
      },
    };
  },
  methods: {
    checkHandler(data,val){
      this.checkedNodeList = val.checkedNodes
    },
    /**
     * 获取课时类型
     */
    async courseCatalog() {
      let responseData = await courseCatalog(
        {
          pageNum: 1,
          pageSize: 1000,
          needCount: true
        }
      );
      if (responseData && responseData.state == "success") {
        this.courseCatalogList = responseData.body.list;
      }
    },
    openDialog() {
      this.formInfo = {
        merchantId:this.$route.query.id
      }
      this.getMerchantList()
      this.courseCatalog()
    },

    async distributeCourse() {
      // if(this.checkedNodeList.length === 0) {
      //   return false
      // }
      // this.checkedNodeList.forEach(item => {
      //   item.merchantId = this.formInfo.merchantId
      // })

      // let responseData = await distributeCourseOnBuyGoods(
      //   JSON.stringify(this.checkedNodeList)
      // );
      //
      // if (responseData && responseData.state == "success") {
      //   window.$msg('分配成功')
      // }
    },
    async buyCourseGoods() {
      let responseData = await buyCourseGoods(
        {
          ...this.formInfo
        }
      );

      if (responseData && responseData.state == "success") {
        // this.distributeCourse()
        window.$msg('购买成功')
        this.getMerchantList()
      }
    },
    async getMerchantList() {
      let responseData = await getMerchantList(
        {
          pageNum: 1,
          pageSize: 10000,
          needCount: true,
          merchantStatus: '正常'
        }
      );

      if (responseData && responseData.state == "success") {
        this.merchantList = responseData.body.list;
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.buyCourseGoods()
          this.$emit('updateView')
          this.dialogVisible = false
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 30px;
}

@import "../../style/dialog.scss";

</style>
